@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  font-family: 'Manrope', sans-serif;
  font-size: 18px;
}

body {
  background-color: hsl(218, 23%, 16%);
}

button{
  padding: 16px;
  position: relative;
  top: 32px;
  left:41%;
  background-color: hsl(150, 100%, 66%) ;
  border-radius: 50%;
  transition: .3s ease-in-out;
}

button:hover {
  box-shadow: 2px 2px 12px 4px rgba(255, 255, 255);
}

@media (min-width: 760px ) {
  button{
    left: 45%;
  }
}